import React from 'react';
import style from './Paymentgate.module.css';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner'
import {API_BASE_URL,IMG_URL} from '../../config/config';

export default function Paymentgate() {
    const fetchConfig = getFetchConfig();
    const [selectedOption, setSelectedOption] = useState('');
    const [cartOrderSummary, setCartOrderSummary] = useState([]);
    const [allCartProduct, setAllCartProduct] = useState([]);
    const [userProfileInfo, setUserProfileInfo] = useState(``);
    const [userAllProfile, setUserAllProfile] = useState([]);
    const [shipping_adddress, setshipping_adddress] = useState('')
    const [selectedOptions, setSelectedOptions] = useState('COD')
    const [shipmentCost, setShipmentCost] = useState(0);
    const [productId, setProductId] = useState(''); 
    const [productIds, setProductIds] = useState([]);
    const [promotionDiscount, setPromotionDiscount] = useState(null);

    const [loader, setLoader] = useState(false)
    let data = window.localStorage.getItem('data')
    console.log(data, "1234")
    const navigate = useNavigate()
    
    
    
    const fetchAllCartProduct = () => {
        fetch(`${API_BASE_URL}/cart/getAllProduct`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                data: data
            })

        })

            .then(handleFetchErrors)
            .then((dataAllCartProduct) => {
                setAllCartProduct(dataAllCartProduct.data.product);
                setCartOrderSummary(dataAllCartProduct.data);
                setProductIds(dataAllCartProduct?.data?.product);
            })
            .catch(standardFetchHandlers.error);
    };


    const handleOptionChange = (event) => {
        setSelectedOptions(event.target.value);
    };

    const fetchUserAllProfile = () => {
        fetch(`${API_BASE_URL}/profile`, {
            ...fetchConfig,
            method: `GET`,
        })
            .then(handleFetchErrors)
            .then((dataUserAllProfile) => {
                setUserAllProfile(dataUserAllProfile.data.user);
                setUserProfileInfo(dataUserAllProfile.data.user.userInfo);
            })
            .catch(standardFetchHandlers.error);
    };

    let shippingaddress = localStorage.getItem('shippingAddress');
    let billingaddress = localStorage.getItem('billingAddress');
    let storeId = localStorage.getItem('pickupstoreId')
    console.log(shippingaddress, 'shippingAddress');
    console.log(billingaddress, 'billingAddress');



    const getShippingAdress = () => {
        const id = window.localStorage.getItem('shippingAddress')
        fetch(`${API_BASE_URL}/productOrder/shipping_adddress/${id}`, {
            ...fetchConfig,
            method: 'GET',
        })
            .then(handleFetchErrors)
            .then((address) => {
                
                setshipping_adddress(address.shippingAddress)
                console.log("cityfetchhhhhh", address.shippingAddress)
            })
            .catch(standardFetchHandlers.error);
    }
    // const fetchShipmentCost = async () => {
    //     try {
    //         const cityId = shipping_adddress.city;
    //         console.log('shipppingaddresssgggg', shipping_adddress)
    //         const productId = productIds?.map(product => product?.productId);
    //         console.log('productidfetchhhhhh', productId);

    //         const queryParams = new URLSearchParams({
    //             city: cityId,
    //             products: JSON.stringify(productId)
    //         });

    //         console.log("query.....", queryParams)

    //         const response = await fetch(`${API_BASE_URL}/product/shipment-cost?${queryParams}`);

    //         const data = await response.json();
    //         console.log("shipmentCost show for better understand...............................hefs", data)

    //         if (response.ok) {
    //             setShipmentCost(data.data.shipmentCost);
    //         } else {
    //             console.error('Error fetching shipment cost:', data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching shipment cost:', error);
    //     }
    // };

    const getPlaceOrderOnline = async () => {
        setLoader(true);
        try {
            // await fetchShipmentCost();

            const response = await fetch(`${API_BASE_URL}/productOrder/placeOrder`, {
                ...fetchConfig,
                method: 'POST',
                body: JSON.stringify({
                    shippingAdressId: shippingaddress,
                    billingAddressId: billingaddress,
                    pickupstoreId: storeId,
                    paymentType: "online",
                    data: data
                })
            })
            const dataplace = await response.json();
            if (response.ok) {
                window.localStorage.removeItem('ProductCart');
                if (dataplace.data.orderId) {
                    navigate(`/paymentForm/${dataplace.data.orderId}`);
                    localStorage.removeItem('pickupstoreId');
                    localStorage.removeItem('billing_id');
                    localStorage.removeItem('billingAddress');
                }
            } else {
                console.error('Error placing online order:', dataplace.message);
            }
        } catch (error) {
            console.error('Error placing online order:', error);
        } finally {
            setLoader(false);
        }
    };

    const getPlaceOrderCod = async () => {
        setLoader(true);
        try {
            // await fetchShipmentCost(); // Fetch shipment cost before placing order

            const response = await fetch(`${API_BASE_URL}/productOrder/placeOrder`, {
                ...fetchConfig,
                method: 'POST',
                body: JSON.stringify({
                    shippingAdressId: shippingaddress,
                    billingAddressId: billingaddress,
                    pickupstoreId: storeId,
                    paymentType: "cod",
                    data: data
                })
            });

            const dataplace = await response.json();

            if (response.ok) {
                console.log(dataplace.data, "smkkasdk");
                window.localStorage.removeItem('ProductCart');
                if (dataplace.data.orderId) {
                    toast.success('Order is Placed Successfully');
                   navigate('/Sucessmessage', { state: { responseData: dataplace.data } });
                    localStorage.removeItem('pickupstoreId');
                    localStorage.removeItem('billing_id');
                    localStorage.removeItem('billingAddress');
                }
            } else {
                console.error('Error placing COD order:', dataplace.message);
                // Handle error
            }
        } catch (error) {
            console.error('Error placing COD order:', error);
            // Handle error
        } finally {
            setLoader(false);
        }
    };

       const fetchActivePromotion = async () => {
                    //setLoadingPromotion(true);
                try {
                    const response = await fetch(`${API_BASE_URL}/promotions`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    });
                    const data = await response.json();
                    
                    if (data && data.data.promotionDiscount) {
                    
                    setPromotionDiscount(data.data.promotionDiscount); // Assuming the active promotion is stored in 'data.promotion'
                    }
                } catch (error) {
                    console.error('Error fetching active promotion:', error);
                } finally {
                    //setLoadingPromotion(false);
                }
         };


    useEffect(() => {
        fetchAllCartProduct();
        fetchUserAllProfile();
        getShippingAdress() ; 
        fetchActivePromotion() ; 
        // fetchShipmentCost() ; 
        // getPlaceOrder()
    }, []);

    // useEffect(() => {
    //     if (shipping_adddress?.city && productIds?.length > 0) {
    //         fetchShipmentCost();
    //     }
    // }, [shipping_adddress, productIds]);


    const handleCheckboxChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
        <div className={style.main_wrapper}>
            <div className={style.checkout_cart}>
                <h1>Checkout</h1>
                <p>{allCartProduct?.length || 0} item(s) in Cart</p>
            </div>
            <div className={style.checkout_cart_coupancode}>
                <div className={style.checkout_cart_heading}>
                    <img src="/assets/Group 569 (1).png" alt="" />
                    <span>Have a coupon? <h4>Click here to enter your code</h4></span>
                </div>
                <div>
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
            <div className={style.checkout_cart_customer_product}>
                <div className={style.checkout_cart_customer_products}>
                    <div className={style.checkout_cart_customer}>
                        <span><i class="fa-solid fa-check"></i> <h3>Customer</h3></span>
                        <div className={style.checkout_form_box}>
                            <h4>{userProfileInfo.email_address}</h4>

                        </div>
                    </div>
                    {shippingaddress ? (
                        <div className={style.checkout_cart_gray_box}>
                            <span><i class="fa-solid fa-check"></i> <p>Deliver at your Door Step</p></span>
                            <h5>Shipping address</h5>
                            <div className={style.checkout_gatway_wrapper}>
                                <p>{shipping_adddress.state}</p>
                                <h3>{shipping_adddress?.city?.title}</h3>
                            </div>
                            <div className={style.checkout_gatway_wrapper}>
                                <p>{shipping_adddress.address}</p>
                                <h4><span>{shipping_adddress.zipCode}</span> <span>{shipping_adddress.country}</span> </h4>
                            </div>
                            <div className={style.payment_gateway_text}>
                                <h4>3-5 working Days - Task on Clickup</h4>
                            </div>
                        </div>) : null}
                    <div className={style.payment_gateway_details}>
                        <span>3<p>Payment</p></span>
                    </div>

                    <div className={style.payment_gateway_login}>
                        <div className={style.custom_radio_wrap}>
                            <form>
                                <div className={style.form_group}>
                                    <div className={style.comment_form_cookies_consent}>
                                        <input
                                            type="radio"
                                            className={style.custom_checkbox}
                                            value="COD"
                                            id="COD"
                                            checked={selectedOptions === 'COD'}
                                            onChange={handleOptionChange}
                                        />

                                        <label className={style.payment_gateway_radio_btn} htmlFor="COD">Cash On Delivary</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <div>
                            <img src="/assets/Icons_Filled.svg" alt="" />
                        </div> */}
                    </div>
                    <div className={style.payment_gateway_login}>
                        <div className={style.custom_radio_wrap}>
                            <form>
                                <div className={style.form_group}>
                                    <div className={style.comment_form_cookies_consent}>
                                        <input
                                            type="radio"
                                            className={style.custom_checkbox}
                                            value="card"
                                            id='card'
                                            checked={selectedOptions === 'card'}
                                            onChange={handleOptionChange}

                                        />
                                        <label className={style.payment_gateway_radio_btn} htmlFor="card">Card</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <div>
                            <img src="/assets/Frame 575.svg" alt="" />
                        </div> */}
                    </div>
                    <button className={style.place_order_btn} onClick={selectedOptions === 'COD' ? getPlaceOrderCod : getPlaceOrderOnline} disabled={loader == true}> {loader == true ? (<ThreeDots
                        height="10"
                        width="40"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                    ) : "Place order"}</button>
                </div>

                <div className={style.checkout_order_summery}>
                    <h2>Order Summary</h2>
                    {allCartProduct?.length > 0 && allCartProduct.map((allproductByFilter) => {
                        return (
                            <div className={style.checkout_items_products}>
                                <div className={style.checkout_items_oil_filters}>
                                    <div className={style.paymentgate_wrap_images}>
                                        <img src={allproductByFilter.thumbnail ? `${IMG_URL}/${allproductByFilter.thumbnail}` : `/assets/Rectangle 114 (1).png`} alt="" />
                                    </div>
                                    <div>
                                        <div>
                                            <h5>{allproductByFilter.title}</h5>
                                        </div>
                                        <div>
                                            <h4>{allproductByFilter.sku}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h5>{allproductByFilter.price.toFixed(2)} {allproductByFilter.currency}</h5>
                                    </div>
                                    <div>
                                        <h4>{allproductByFilter.quantity} item</h4>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }

                    <div className={style.order_summary_section}>
                        <div className={style.order_summary}>
                            <div className={style.order_summary_lists}>
                                <p>Sub Total:</p>
                                 {promotionDiscount && <p>Discount({promotionDiscount}%):</p>}
                                  <p>Shipping Cost:</p>
                                {/* <p>Shipping Cost:</p> */}
                                {/* <p>Coupon card:</p> */}
                                <p>VAT(15%):</p>
                                <h2>Total:</h2>
                            </div>

                            {/* <div className={style.order_summary_lists1}>
                                <p>{cartOrderSummary.subTotal} SAR</p>
                                <p>{cartOrderSummary.shippingCost} SAR</p>
                                {/* <p>-</p> 
                                <p>{Number(cartOrderSummary.vat).toFixed(2)}</p>
                                {/* <h2>{cartOrderSummary.total} SAR</h2> 
                                <h2>{Number(cartOrderSummary?.total).toFixed(2)} SAR</h2>

                            </div> */}
                            {/* <div className={style.order_summary_lists1}>
                                {promotionDiscount ? (
                                    <>
                                    <p>{(cartOrderSummary.subTotal.toFixed(2))}SAR</p>
                                    <p>{(cartOrderSummary.subTotal*promotionDiscount/100).toFixed(2)}SAR</p>
                                    <p>{cartOrderSummary.shippingCost.toFixed(2)} SAR</p>
                                    <p> {((cartOrderSummary.subTotal- cartOrderSummary.subTotal*promotionDiscount/100)*0.15).toFixed(2)}SAR</p>
                                    <h2>{((cartOrderSummary.subTotal - cartOrderSummary.subTotal*promotionDiscount/100)+((cartOrderSummary.subTotal- cartOrderSummary.subTotal*promotionDiscount/100)*0.15)+ cartOrderSummary.shippingCost).toFixed(2) } SAR</h2>
                                    </>
                                ) : (
                                    <>
                                    <p>{cartOrderSummary.subTotal.toFixed(2)}SAR</p>
                                    <p>{cartOrderSummary.shippingCost}.toFixed(2) SAR</p>
                                    <p>{cartOrderSummary.vat.toFixed(2)}SAR</p>
                                    <h2>{cartOrderSummary.total.toFixed(2)}SAR</h2>
                                    </>
                                )}
                          </div> */}

                        <div className={style.order_summary_lists1}>
                            {promotionDiscount ? (
                                <>
                                    <p>{(cartOrderSummary.subTotal && !isNaN(cartOrderSummary.subTotal) ? cartOrderSummary.subTotal.toFixed(2) : '0.00')} SAR</p>
                                    <p>{(cartOrderSummary.subTotal && !isNaN(cartOrderSummary.subTotal) && promotionDiscount && !isNaN(promotionDiscount) 
                                        ? (cartOrderSummary.subTotal * promotionDiscount / 100).toFixed(2) 
                                        : '0.00')} SAR</p>
                                    <p>{(cartOrderSummary.shippingCost && !isNaN(cartOrderSummary.shippingCost) ? cartOrderSummary.shippingCost.toFixed(2) : '0.00')} SAR</p>
                                    <p>{(cartOrderSummary.subTotal && !isNaN(cartOrderSummary.subTotal) && promotionDiscount && !isNaN(promotionDiscount) 
                                        ? ((cartOrderSummary.subTotal - cartOrderSummary.subTotal * promotionDiscount / 100) * 0.15).toFixed(2) 
                                        : '0.00')} SAR</p>
                                    <h2>{((cartOrderSummary.subTotal - cartOrderSummary.subTotal*promotionDiscount/100)+((cartOrderSummary.subTotal- cartOrderSummary.subTotal*promotionDiscount/100)*0.15)+ cartOrderSummary.shippingCost).toFixed(2) } SAR</h2>
                                </>
                            ) : (
                                <>
                                    <p>{(cartOrderSummary.subTotal && !isNaN(cartOrderSummary.subTotal) ? cartOrderSummary.subTotal.toFixed(2) : '0.00')} SAR</p>
                                    <p>{(cartOrderSummary.shippingCost && !isNaN(cartOrderSummary.shippingCost) ? cartOrderSummary.shippingCost.toFixed(2) : '0.00')} SAR</p>
                                    <p>{(cartOrderSummary.vat && !isNaN(cartOrderSummary.vat) ? cartOrderSummary.vat.toFixed(2) : '0.00')} SAR</p>
                                    <h2>{(cartOrderSummary.total && !isNaN(cartOrderSummary.total) ? cartOrderSummary.total.toFixed(2) : '0.00')} SAR</h2>
                                </>
                            )}
                        </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}