import React, { useState, useEffect } from 'react';
import MemberLayout from '../../layouts/memberLayout/memberLayout';
import { Link, useParams } from 'react-router-dom';
import {API_BASE_ADMIN, API_BASE_URL} from '../../config/config';
import DynamicFieldComponents from '../../components/order/DynamicFieldComponents';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import style from './specialOrder.module.css';
import getFetchConfig from '../../utils/getFetchConfig';

import whatsappIcon from '../../assets/images/whatsappicon.jpg';
import gmailIcon from '../../assets/images/gmailicon.jpg';
import CancelPopup from '../../components/order/successfulPopup';

const SpecialOrderPage = () => {
    const { orderId } = useParams();
    // const { vinNumber: vinNumberParam } = useParams();
    const [vinNumber, setVinNumber] = useState(); // Set initial VIN number from URL parameter
    const [fields, setFields] = useState([]);
 
    const [productName, setProductName] = useState('');

    const fetchConfig = getFetchConfig();
    const WHATSAPP_INFO = '+923420699570';
    const GMAIL_INFO = 'noreply.anycarpart@gulfmotors.sa';
    const [dynamicFields, setDynamicFields] = useState([]);
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false); // State variable for success popup
    const urlSearchParams = new URLSearchParams(window.location.search);
    const vinNumberParam = urlSearchParams.get('vinNumber');
    console.log("VIN Number from URL parameter:", vinNumberParam);
    
    const handleDynamicFields = (ques, response) => {
        dynamicFields[`${ques}`] = response
        console.log("dynamicFields", dynamicFields)
    }

    useEffect(() => {
        const fetchRequestForm = async () => {
            try {
                const response = await fetch(`${API_BASE_ADMIN}/admin/special-orders/get-order-request-form?vinNumber=${vinNumberParam}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch request form');
                }
                const data = await response.json();
                setFields(data.data.fields);
                setVinNumber(vinNumberParam); // Set the VIN number from URL param
            } catch (error) {
                console.error('Error fetching request form:', error);
                toast.error('Error fetching request form');
            }
        };

        fetchRequestForm();
    }, [vinNumberParam]);

    const handleSubmitRequest = (e) => {
        e.preventDefault();

        const vinNumber = document.getElementById('vinNumber').value;
        const productName = document.getElementById('productName').value;

        const fields = Object.values(dynamicFields);

        if (!vinNumber) {
            toast.error("VIN number is required.");
            return;
        }

        if (fields.length === 0) {
            toast.error("Fields are required.");
            return;
        }

        const requestBody = {
            fields: fields,
            vinNumber: vinNumber,
            productName: productName,
        };

        fetch(`${API_BASE_URL}/specialOrder/special-order-request`, {
            ...fetchConfig,
            method: 'POST',
            body: JSON.stringify(requestBody),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log("Request submitted successfully:", data);
            setIsSuccessPopupVisible(true); 
        })
        .catch(error => {
            console.error('Error submitting request:', error);
            toast.error("Error submitting request. Please try again later.");
        });
    };


    return (
        <div className={style.special_order_page_container}>
            <h1 className={style.special_order_page_heading}>Special Order Form</h1>
            <div className={style.special_order_form_container}>
                <div style={{ backgroundColor: '#F7F7F7', borderRadius: 10, paddingTop:'10px', marginBottom: 15 }} className={style.vin_number_input_container}>
                    <label htmlFor="vinNumber" style={{ fontWeight: 'bold',marginLeft:5, padding: 15 }} className={style.input_label}>Your Searched VIN number</label>
                    <input
                        type="text"
                        id="vinNumber"
                        className={style.special_order_form_input}
                        placeholder="VIN Number"
                        value={vinNumber} // Set the value attribute to vinNumber
                        style={{ backgroundColor: '#F7F7F7',marginLeft:5, padding: 15 }}
                        onChange={(e) => setVinNumber(e.target.value)}
                    />
                    <label htmlFor="productName" style={{ fontWeight: 'bold',marginLeft:5, padding: 15 }} className={style.input_label}>Enter Product Name</label>
                    <input
                        type="text"
                        id="productName"
                        className={style.special_order_form_input}
                        placeholder="Enter Product Name"
                        value={productName}
                        style={{ backgroundColor: '#F7F7F7',marginLeft:5, padding: 15 }}
                        onChange={(e) => setProductName(e.target.value)}
                    />
                
                </div>

                <DynamicFieldComponents
                    fields={fields}
                    handleDynamicFields={handleDynamicFields}
                    // onSubmit={handleSubmitRequest}
                />
                 <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <button
                                onClick={handleSubmitRequest}
                                style={{
                                    backgroundColor: '#212F63',
                                    color: '#fff',
                                    padding: '12px 30px',
                                    borderRadius: '5px',
                                    border: 'none',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                }}
                            >
                                Submit
                            </button>

                        </div>
            </div>
            <p className={style.additional_info_text}>
                For any queries, contact us via
                <br />
                <div className={style.contact_icons_container}>
                    <span className={style.contact_link} onClick={() => window.open(`https://wa.me/${WHATSAPP_INFO}`, '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" className={style.contact_icon} />
                        WhatsApp
                    </span>
                    <span className={style.contact_link} onClick={() => window.open(`mailto:${GMAIL_INFO}`, '_blank')}>
                        <img src={gmailIcon} alt="Gmail" className={style.contact_icon} />
                        Gmail
                    </span>
                </div>
            </p>
            <Link to={`/my-special-orders`}>
                {isSuccessPopupVisible && <CancelPopup title="Your special order placed successfully!" onClose={() => setIsSuccessPopupVisible(false)} />} 
            </Link>
        </div>
    );
};

export default SpecialOrderPage;
