import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import CommonModalWrapper from '../common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../Icons/CloseIcon';
import SubCategoriesCard from '../product/subcategories-cards/SubCategoriesCard';
import SearchProduct from '../search-product/SearchProduct';
import PopupMessage from '../auth/popup-message/popupMessage';
import RegisterCard from '../auth/register-card/RegisterCard';
import LoginCard from '../auth/login-card/LoginCard';
import ForgotPasswordCard from '../auth/forgot-password-card/ForgotPasswordCard';
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import LocaleContext from "../../LocaleContext";
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../components/loader/Loader';
import i18n from "../../i18n";
import style from './header.module.css';
import { ThreeDots } from 'react-loader-spinner'
import { API_BASE_URL } from '../../config/config';
// import Loader from "react-js-loader";

import './css.css';
import axios from 'axios';
import { Button } from '@mui/material';
import { updateCartCount } from '../../utils/commonFunctions';
import { Alert, AlertHeading } from 'react-bootstrap';

export default function Header({ setdata, setlocalVinNumber, setsubcategory, setproductdata, message, setcartCount, cartWishCount, setCartWishCount, cartCount }) {
  const navigate = useNavigate(),
    fetchConfig = getFetchConfig();
  const Wishlist = window.localStorage.getItem('Product');
  const WishlistProduct = Wishlist ? JSON.parse(Wishlist) : [];
  const Cart = window.localStorage.getItem('ProductCart')
  const CartProduct = Cart ? JSON.parse(Cart) : [];
  console.log(WishlistProduct, "helll")
  //  const API_BASE_URL = `https://admin-anycarpart.go-demo.com/api/v1`;
  // const API_BASE_URL = `http://localhost:3003/api/v1`;
  const imageURL = `https://admin-anycarpart.go-demo.com`;


  const { t } = useTranslation(),
    { locale } = useContext(LocaleContext);

  console.log(locale, "localelocale")

  function changeLocale(e) {

    if (locale !== e.target.value) {
      localStorage.setItem(`AnyCarPartLang`, e.target.value)
      i18n.changeLanguage(e.target.value);
    }

    // window.location.reload();
    updateCartCount(setcartCount, setCartWishCount);

  }
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  const handleCloseWelcomeMessage = () => {
    setShowWelcomeMessage(false);
  };
  // useEffect(() => {
  //   const isFirstVisit = isFirstVisitAfterRegistration();
  //   if (isFirstVisit) {
  //     setTimeout(() => {
  //       setShowWelcomeMessage(false);
  //     }, 3000);
  //   } else {
  //     const messageShown = localStorage.getItem('welcomeMessageShown');
  //     if (messageShown) {
  //       setShowWelcomeMessage(false);
  //     }
  //   }
  // }, []);

  const [state, setState] = useState(false);
  const [modalIsOpen, updateModalVisibility] = useState(false);

  const [modalIsOpenForPopupMessage, updateModalIsOpenForPopupMessage] = useState(false);
  const [modalIsOpenForLogin, updateModalVisibilityLogin] = useState(false);
  const [modalIsOpenForForgotPassWord, updateModalVisibilityForForgotPassWord] = useState(false);
  const [modalIsOpenForSearch, updateModalVisibilityForSearch] = useState(false);
  // const [cartWishCount, setCartWishCount] = useState();
  // const [cartCount,setcartCount]=useState()
  const [popupStyle, setPopupStyle] = useState(true);
  const [vinNumber, SetVinNUmber] = useState()
  const [UserformData, serformData] = useState()
  const [ly_car_ids, setly_car_ids] = useState()
  const [partsNumber, setpartsNumber] = useState()
  const [loader, setLoader] = useState(false)
  const [loader1, setLoader1] = useState(false)
  const [activeMenu, setActiveMenu] = useState(null);
  const tokenExists = window.localStorage.getItem('token')
  const WishlistcountToDisplay = tokenExists ? cartWishCount : WishlistProduct.length;
  const CartCountToDiplay = tokenExists ? cartCount : CartProduct.length

  const toggleModalForRegisterForSearch = () => {
    updateModalVisibilityForSearch(true)
  }

  const toggleModalForRegister = (toggleValue = false) => {
    updateModalVisibilityLogin(false)
    updateModalVisibility(toggleValue)
  }

  const toggleModalForLogin = (toggleValue = false) => {
    updateModalVisibility(false)
    updateModalVisibilityForForgotPassWord(false)
    updateModalVisibilityLogin(toggleValue)
  }

  const toggleModalForForgotPassWord = (toggleValue = false) => {
    updateModalVisibilityLogin(false)
    updateModalVisibilityForForgotPassWord(toggleValue)
  }

  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState('');

  const [tokenExist, updateToken] = useState(false);
  const [wishListExist, updateWishList] = useState(false);

  // const [isOpen, setIsOpen] = useState(false);

  const handleMouseOver = () => {
    setIsOpen(false);
  };

  const handleTabClick = () => {
    setIsOpen(false);
    setColor('purple');
  };


  // fetching menu data
  const [subMenuData, updateSubMenuData] = useState([]),
    [menuData, updateMenuData] = useState([]);


  const fetchingMenuData = async () => {
    try {

      fetch(`${API_BASE_URL}/account/signin`, {
        ...fetchConfig,
        method: "GET"
      })
        .then(handleFetchErrors)
        .then((res) => {

        }).catch((err) => {

        })

    } catch (err) {

    }

  }
  const [userAllProfile, setUserAllProfile] = useState([])
  const [userDetails, setUserDetails] = useState(null);
  const fetchUserAllProfile = () => {

    fetch(`${API_BASE_URL}/profile`, {
      ...fetchConfig,
      method: `GET`,
    })
      .then(handleFetchErrors)
      .then((dataUserAllProfile) => {
        console.log(dataUserAllProfile.data, "helll");
        setUserAllProfile(dataUserAllProfile.data.user);
        const createdAtDate = new Date(dataUserAllProfile.data.user.createdAt);
        const oneDayAgo = new Date();
        oneDayAgo.setDate(oneDayAgo.getDate() - 1);
        console.log('createdAtDate:', createdAtDate);
        console.log('oneDayAgo:', oneDayAgo);
        if (createdAtDate.getTime() >  oneDayAgo.getTime()) {
          setShowWelcomeMessage(true);
        } else {

          setShowWelcomeMessage(false); 
        }
        // console.log('userinfoooooooooooooooooooooo,', dataUserAllProfile.data.user.createdAt)
      })
      .catch(standardFetchHandlers.error);
  };
  useEffect(() => {
    fetchUserAllProfile();
  }, []);
  
  const [dropdownIsOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setDropdownOpen(!dropdownIsOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        console.log("Clicked outside dropdown");
        setDropdownOpen(false);
      }
    };

    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  // const data = [
  //   {
  //     type: `anchor`,
  //     name: `All product`,
  //     url: `google.com`,
  //     data: []
  //   },
  //   {
  //     type: `category`,
  //     name: `wash`,
  //     url: null,
  //     data: [{
  //       name: `tes`,
  //       image: `/subcategory/subcategory_1680268036087128989on.png`
  //     }]
  //   }
  // ];
  const [menuLists, updateMenuLists] = useState([]);


  const fetchMenuLists = () => {

    fetch(`${API_BASE_URL}/menu`, {
      ...fetchConfig,
      method: `GET`,
    })
      .then(handleFetchErrors)
      .then((MenuList) => {
        // console.log(MenuList.data.menu);
        updateMenuLists(MenuList.data.menu);
      })
      .catch(standardFetchHandlers.error);

  };
  const menuSubCategoriesHandler = (menuName) => {
    if (!isOpen) document.body.classList.add("body-fixed");
    else document.body.classList.remove("body-fixed");
    setIsOpen(!isOpen)
    setActiveMenu(menuName);
    const foundMenuData = menuLists.find((d) => d.name == menuName);
    if (foundMenuData && Object.keys(foundMenuData).length) {

      const subMenuArr = foundMenuData?.subMenuData;
      updateSubMenuData(subMenuArr)
    }

  }




  useEffect(() => {

    fetchMenuLists()
  }, [state]);



  const logoutFunction = (toggleValue = false) => {
    window.localStorage.removeItem('token')
    navigate("/")
    window.location.reload()
    // updateModalIsOpenForPopupMessage(toggleValue)
    // localStorage.clear();
    // updateToken(false)

  }


  useEffect(() => {

    if (localStorage.getItem(`token`)) {
      updateToken(true)
    }
  }, [localStorage.getItem(`token`), tokenExist])




  const fetchCartWishCount = () => {

    fetch(`${API_BASE_URL}/account/cartCount`, {
      ...fetchConfig,
      method: `GET`,
    })
      .then(handleFetchErrors)
      .then((dataCount) => {
        // console.log('-------------------');
        console.log(dataCount.data, "------------------------->")
        setCartWishCount(dataCount.data.favoriteProductCount);
        setcartCount(dataCount.data.cartCount)
        updateCartCount(setcartCount, setCartWishCount);

      })
      .catch(standardFetchHandlers.error);

  };


  useEffect(() => {
    console.log(">>>>>>>>>>>>>>>>>>>>");
    updateCartCount(setcartCount, setCartWishCount);
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //       updateCartCount(setcartCount, setCartWishCount);
  //       // console.log("Periodically fetching cart count...");
  //   }, 1000); // update every 10 seconds

  //   return () => clearInterval(intervalId); // Cleanup interval on component unmount
  // }, []);



  useEffect(() => {

    fetchCartWishCount()
  }, []);
  useEffect(() => {
    // console.log(localStorage.getItem(`wishList`));
    if (localStorage.getItem(`wishList`)) {

      fetchCartWishCount();
      updateWishList(false);
      localStorage.removeItem(`wishList`);
    }
  }, [localStorage.getItem(`wishList`), wishListExist])

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const apiHandler = (event) => {
    SetVinNUmber(event.target.value)

  }


  const ApisHandlers = (event) => {
    event.preventDefault()
    setLoader(true)
    axios.get(`${API_BASE_URL}/product/getVinder?vinNumber=${vinNumber}`)
      .then((response) => {
        setLoader(false)
        console.log(response.data)
        if (response.data.status == 400) {
          toast.error(response.data.data.message);
        }
        console.log('viiiiiiiiiiii', vinNumber.length === 17, vinNumber.length)
        // if (vinNumber.length === 17 && response.data.data.message === "vinNumber is incorrect") {
        //   navigate(`/special-order?vinNumber=${vinNumber}`);
        //   return;
        // }
        CustomHandler(response.data.data.ly_id)
        setly_car_ids(response.data.data.ly_id)
        serformData(response.data.data.formattedData)
        setdata(response.data.data.formattedData)
        setlocalVinNumber(response.data.data.ly_id)
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  const CustomHandler = (ly_car_ids) => {
    setpartsNumber('');
    console.log("ly_car_ids", ly_car_ids)

    if (ly_car_ids !== undefined) {
      axios
        .get(`${API_BASE_URL}/product/getdata?ly_car_id=${ly_car_ids}`)
        .then((data) => {
          if (data.data.status === 400) {
            if (data.data.data.message === "Product Not FOund ") {
              navigate(`/special-order?vinNumber=${vinNumber}`);
              return;
            } else {
              toast.error(data.data.data.message);
            }
          }

          console.log('data.data.status', data.data.status);
          console.log('data.data.data.message', data.data.data.message);
          const categoryId = data?.data?.data?.Obj?.Category[0]?._id;
          console.log('kiiikkkkikkki....', data?.data?.data?.Obj)
          setsubcategory(data.data.data.Obj.Category)
          let queryString = `?vinnumber=${vinNumber}&categoryId=${categoryId}&ly_car_ids[0]=${ly_car_ids}`;

          data.data.data.Obj.subcategory.forEach((number, index) => {
            queryString += `&subCategoryId[${index}]=${number._id}`;
          });

          data.data.data.Obj.oe_number.forEach((number, index) => {
            queryString += `&oe_number[${index}]=${number}`;
          });

          navigate({
            pathname: '/product',
            search: `${queryString}`,
          });
        })
        .catch((err) => {
          console.log(err, "jjjjjj");
        });
    }
  }



  // useEffect(()=>{
  //    console.log(ly_car_ids,"dddddddddddddddddddddddddddd")
  //    if(ly_car_ids!=undefined){
  //     axios
  //     .get(`${API_BASE_URL}/product/getdata?ly_car_id=${ly_car_ids}`)
  //     .then((data) => {
  //         if (data.data.status==400) {
  //             toast.error(data.data.data.message);
  //         }
  //          const categoryId = data.data.data.Obj.Category[0]._id;
  //          setsubcategory(data.data.data.Obj.Category)
  //          let queryString = `?vinnumber=${vinNumber}&categoryId=${categoryId}&ly_car_ids=${ly_car_ids}`;
  //          data.data.data.Obj.subcategory.forEach((number,index)=>{
  //              queryString+=`&subCategoryId[${index}]=${number._id}`
  //          })
  //          data.data.data.Obj.PRIAUTO_Number.forEach((number, index) => {
  //           queryString += `&priauto_number[${index}]=${number}`;
  //         });

  //           navigate({
  //             pathname: '/product',
  //             search: `${queryString}`,
  //           });

  //     })

  //     .catch((err) => {
  //         console.log(err, "jjjjjj");
  //     });
  //    }
  // },[ly_car_ids])

  const regex = /^[A-Za-z0-9]+$/;

  const seachPartsHanler = (event) => {
    event.preventDefault()
    SetVinNUmber('')
    setLoader1(true)
    axios.get(`${API_BASE_URL}/product/seachproduct?priautoNumber=${partsNumber}`)
      .then((response) => {
        if (response.data.status == 400) {
          toast.error(response.data.data.message)
          console.log("gadsjjjjjjjjj", response.data.data)
        }
        if (response.data.data.message === "Sorry, we couldn't find any products matching your search.") {
          console.log('sjfkdfdsjsdfk')
          navigate(`/special-order?oe_number=${partsNumber}`);
        }
        setLoader1(false)
        if (response.data.data.product.length <= 0) {
          toast.error('Sorry, we couldn’t find any products associated with the Priauto number you provided.')
        }
        setproductdata(response.data.data.product)
        let queryString = `?&categoryId=${response.data.data.subcategory[0].categoryId}`;
        response.data.data.subcategory.forEach((number, index) => {
          queryString += `&subCategoryId[${index}]=${number._id}`
        })

        // const isAlphabeticPattern = /^[a-zA-Z\s]+$/;
        // if (isAlphabeticPattern.test(partsNumber)) {
        //   queryString += `&title[0]=${partsNumber}`;
        // } else {
        //   queryString += `&oe_number[0]=${partsNumber}`;
        // }
        const isAlphabeticPattern = /^[a-zA-Z\s]+$/;
        const isAlphaNumericPattern = /^[a-zA-Z0-9\s]+$/;
        const isOENumberPattern = /^[a-zA-Z0-9]+$/;
        if (isAlphabeticPattern.test(partsNumber)) {
          queryString += `&title[0]=${partsNumber}`;
        } else if (isOENumberPattern.test(partsNumber)) {
          queryString += `&oe_number[0]=${partsNumber}`;
        } else {
          queryString += `&title[0]=${partsNumber}`;
        }


        navigate({
          pathname: '/product',
          search: `${queryString}`,
        });
        window.location.reload()

      })
      .catch((error) => {
        console.log(error.message, "kkkkkkkkkk")
        // toast.error("Something went wrong")
      })

  }
  const HomePageHandler = () => {
    setpartsNumber('')
    SetVinNUmber('')
    setlocalVinNumber('')
    setsubcategory('')
    setproductdata('')
    setIsOpen(false)
    navigate('/')
  }


  useEffect(() => {
    console.log("mssss", message)
    if (message == "reset") {

    }
  }, [message])




  return (
    <>
      <CommonModalWrapper isOpen={modalIsOpen} >
        <CloseIcon onClick={() => toggleModalForRegister(false)} />

        <RegisterCard toggleModalForLogin={toggleModalForLogin} updateModalVisibility={updateModalVisibility} />
      </CommonModalWrapper>


      <CommonModalWrapper isOpen={modalIsOpenForLogin} className={style.login_modal_wrapper} >
        <CloseIcon onClick={() => toggleModalForLogin(false)} />

        <LoginCard toggleModalForForgotPassWord={toggleModalForForgotPassWord} toggleModalForRegister={toggleModalForRegister} updateModalVisibilityLogin={updateModalVisibilityLogin} />
      </CommonModalWrapper>

      {/* forgetPassword */}
      <CommonModalWrapper isOpen={modalIsOpenForForgotPassWord} className={style.login_modal_wrapper} >
        <CloseIcon onClick={() => toggleModalForForgotPassWord(false)} />

        <ForgotPasswordCard toggleModalForLogin={toggleModalForLogin} updateModalVisibilityForForgotPassWord={updateModalVisibilityForForgotPassWord} />
      </CommonModalWrapper>

      <CommonModalWrapper ispopupStyle={popupStyle} isOpen={modalIsOpenForPopupMessage} className={style.login_modal_wrapper} >
        <CloseIcon onClick={() => logoutFunction(false)} />

        <PopupMessage logoutFunction={logoutFunction} updateToken={updateToken} updateModalIsOpenForPopupMessage={updateModalIsOpenForPopupMessage} />
      </CommonModalWrapper>

      <CommonModalWrapper isOpen={modalIsOpenForSearch} className={style.search_modal_opener} >
        <CloseIcon onClick={() => toggleModalForRegisterForSearch(false)} />

        <SearchProduct />
      </CommonModalWrapper>

      <div className="top-header-part">
        <div className="top-header">
          <div className="telephone">
            <a href="#">{t('HeaderPhoneNumber')}</a>
          </div>
          <div className="order-lists">
            <Link to="/buy-wholesale"> <a href="#">{t('Header1')} </a></Link>
            <Link to="/track-order"><a href="#">{t('Header2')} </a></Link>
            <Link to="/faqs"><a href="#">{t('Header3')} </a></Link>
            <Link to="/help-center1"><a href="#">{t('Header4')} </a></Link>
            <div class="language-select-box">
              <select onChange={changeLocale} >
                <option selected={locale === "ar" ? true : false} value="ar"> العربية</option>
                <option selected={locale === "en" ? true : false} value="en">English</option>
              </select>
            </div>
          </div>
        </div>


        <select name="pets" id="pet-select">
          <option value=""></option>
          <option value="dog">{t('HeaderProducts')}</option>
          <option value="cat">{t('HeaderDeals')}</option>
          <option value="hamster">{t('HeaderCar')}</option>
          <option value="parrot">{t('HeaderInterior')}</option>
          <option value="spider">{t('HeaderExterior')}</option>
          <option value="goldfish">{t('HeaderPerformance')}</option>
          <option value="goldfish">{t('HeaderLightning')}</option>
          <option value="goldfish">{t('HeaderTyres')}</option>
          <option value="goldfish">{t('HeaderBody')}</option>
          <option value="goldfish">{t('HeaderAudio')}</option>
          <option value="goldfish">{t('HeaderAutomotive')}</option>
          <option value="goldfish">{t('HeaderSpeciality')}</option>
        </select>
      </div>
      <div className="top-navbar">
        <div className='logo-box'>
          <div className="logo">
            <div onClick={HomePageHandler}>
              <img src="/assets/Logo-design.svg" alt="Logo Image" />
            </div>

          </div>

        </div>

        <div className="search-boxes">
          <div className="searchbar-box">
            <form className="search-box" onSubmit={seachPartsHanler}>

              <input
                type="search"
                name="partsNumber"
                placeholder={t('HeaderSearchPartBtn')}
                id="search-input" value={partsNumber}
                onChange={(event) => { setpartsNumber(event.target.value) }} />
              {
                loader1 ? (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" type='submit' onClick={seachPartsHanler}>
                    <g clip-path="url(#clip0_1_6090)">
                      <path d="M13.6717 12.4628L16.8839 15.6743L15.8227 16.7355L12.6112 13.5233C11.4162 14.4812 9.92994 15.0022 8.39844 15C4.67244 15 1.64844 11.976 1.64844 8.25C1.64844 4.524 4.67244 1.5 8.39844 1.5C12.1244 1.5 15.1484 4.524 15.1484 8.25C15.1506 9.7815 14.6296 11.2678 13.6717 12.4628ZM12.1672 11.9063C13.119 10.9274 13.6506 9.61532 13.6484 8.25C13.6484 5.349 11.2987 3 8.39844 3C5.49744 3 3.14844 5.349 3.14844 8.25C3.14844 11.1503 5.49744 13.5 8.39844 13.5C9.76376 13.5022 11.0759 12.9706 12.0547 12.0188L12.1672 11.9063Z" fill="#212F63" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_6090">
                        <rect width="18" height="18" fill="white" transform="translate(0.148438)" />
                      </clipPath>
                    </defs>
                  </svg>
                )
              }

            </form>
          </div>

          <div className="searchbar-box">
            <form className="search-box" onSubmit={ApisHandlers}>
              <input
                type="search"
                name="vinNumber"
                placeholder={t('HeaderFindPartBtn')}
                id="search-input"
                value={vinNumber}
                onChange={apiHandler} // Make sure this references the correct function
              />
              {/* <button type="submit"> */}
              {loader ? (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  type="submit"
                  onClick={ApisHandlers}
                >
                  <g clip-path="url(#clip0_1_6090)">
                    <path d="M13.6717 12.4628L16.8839 15.6743L15.8227 16.7355L12.6112 13.5233C11.4162 14.4812 9.92994 15.0022 8.39844 15C4.67244 15 1.64844 11.976 1.64844 8.25C1.64844 4.524 4.67244 1.5 8.39844 1.5C12.1244 1.5 15.1484 4.524 15.1484 8.25C15.1506 9.7815 14.6296 11.2678 13.6717 12.4628ZM12.1672 11.9063C13.119 10.9274 13.6506 9.61532 13.6484 8.25C13.6484 5.349 11.2987 3 8.39844 3C5.49744 3 3.14844 5.349 3.14844 8.25C3.14844 11.1503 5.49744 13.5 8.39844 13.5C9.76376 13.5022 11.0759 12.9706 12.0547 12.0188L12.1672 11.9063Z" fill="#212F63" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_6090">
                      <rect width="18" height="18" fill="white" transform="translate(0.148438)" />
                    </clipPath>
                  </defs>
                </svg>
              )}

              {/* </button> */}
            </form>
          </div>

          {/* Search brands */}
          {/* <div className="searchbar-box">
            <form className="search-box" onSubmit={ApisHandlers}>
              <div class="dropdown">
                <input
                  type="dropdown"
                  name="seachbybrand"
                  placeholder={t('SearchbyBrand')}
                />
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 6L6 11L11 6" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              {/* <input
                type="dropdown"
                name="seachbybrand"
                placeholder={t('SearchbyBrand')}
              // id="search-input"
              // value={vinNumber}
              // onChange={apiHandler} // Make sure this references the correct function
              /> */}
          {/* <button type="submit"> */}
          {/* {loader ? (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  type="submit"
                  onClick={ApisHandlers}
                >
                  <g clip-path="url(#clip0_1_6090)">
                    <path d="M13.6717 12.4628L16.8839 15.6743L15.8227 16.7355L12.6112 13.5233C11.4162 14.4812 9.92994 15.0022 8.39844 15C4.67244 15 1.64844 11.976 1.64844 8.25C1.64844 4.524 4.67244 1.5 8.39844 1.5C12.1244 1.5 15.1484 4.524 15.1484 8.25C15.1506 9.7815 14.6296 11.2678 13.6717 12.4628ZM12.1672 11.9063C13.119 10.9274 13.6506 9.61532 13.6484 8.25C13.6484 5.349 11.2987 3 8.39844 3C5.49744 3 3.14844 5.349 3.14844 8.25C3.14844 11.1503 5.49744 13.5 8.39844 13.5C9.76376 13.5022 11.0759 12.9706 12.0547 12.0188L12.1672 11.9063Z" fill="#212F63" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_6090">
                      <rect width="18" height="18" fill="white" transform="translate(0.148438)" />
                    </clipPath>
                  </defs>
                </svg>
              )} */}

          {/* </button> */}
          {/* </form>


          </div> */}
          {/* <div className="custom-select-content-wrapper">
              <div className="custom-select-form-wrapper">
                <form action="#">
                  <fieldset>
                    <select name="sources" id="sources" className="custom-select sources"
                      data-placeholder={t('HeaderSelectBtn')}>
                      <option value="profile">{t('HeaderSelectBtn')}</option>
                      <option value="word"> Consectetur adipisicing elit</option>
                      <option value="hashtag">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Vitae unde
                        voluptate cumque dolores quod veritatis repudiandae laborum eaque, corrupti
                        architecto.
                      </option>
                    </select>
                  </fieldset>
                </form>
              </div>
            </div> */}

          <div className="icon-boxes">
            <Link to="/Wishlistpage">
              <div className="product-icon">
                <p>{cartWishCount}</p>
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1_6113)">
                    <path d="M9.69216 3.39673C11.4539 1.81498 14.1764 1.86749 15.8737 3.56773C17.5702 5.26873 17.6287 7.97773 16.0507 9.74473L9.69066 16.1137L3.33216 9.74473C1.75416 7.97773 1.81341 5.26423 3.50916 3.56773C5.20791 1.86974 7.92516 1.81273 9.69216 3.39673ZM14.8117 4.62748C13.6867 3.50098 11.8717 3.45524 10.6942 4.51273L9.69291 5.41123L8.69091 4.51348C7.50966 3.45448 5.69841 3.50098 4.57041 4.62898C3.45291 5.74648 3.39666 7.53523 4.42641 8.71723L9.69141 13.9905L14.9564 8.71798C15.9869 7.53523 15.9307 5.74873 14.8117 4.62748Z" fill="#212F63" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_6113">
                      <rect width="18" height="18" fill="white" transform="translate(0.691406)" />
                    </clipPath>
                  </defs>
                </svg>

              </div>
            </Link>

            <Link to="/shopping-cart">
              <div className="product-icon">

                <p>{cartCount}</p>
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1_6121)">
                    <path d="M3.69202 4.81062L1.25977 2.37912L2.32102 1.31787L4.75252 3.75012H16.184C16.3009 3.75011 16.4162 3.77743 16.5207 3.8299C16.6251 3.88236 16.7159 3.95852 16.7857 4.05229C16.8555 4.14607 16.9024 4.25486 16.9227 4.36999C16.943 4.48511 16.9361 4.60339 16.9025 4.71537L15.1025 10.7154C15.0562 10.8699 14.9613 11.0054 14.8319 11.1018C14.7024 11.1981 14.5454 11.2501 14.384 11.2501H5.19202V12.7501H13.442V14.2501H4.44202C4.2431 14.2501 4.05234 14.1711 3.91169 14.0305C3.77103 13.8898 3.69202 13.699 3.69202 13.5001V4.81062ZM5.19202 5.25012V9.75012H13.826L15.176 5.25012H5.19202ZM4.81702 17.2501C4.51865 17.2501 4.2325 17.1316 4.02152 16.9206C3.81054 16.7096 3.69202 16.4235 3.69202 16.1251C3.69202 15.8268 3.81054 15.5406 4.02152 15.3296C4.2325 15.1187 4.51865 15.0001 4.81702 15.0001C5.11538 15.0001 5.40153 15.1187 5.61251 15.3296C5.82349 15.5406 5.94202 15.8268 5.94202 16.1251C5.94202 16.4235 5.82349 16.7096 5.61251 16.9206C5.40153 17.1316 5.11538 17.2501 4.81702 17.2501ZM13.817 17.2501C13.5186 17.2501 13.2325 17.1316 13.0215 16.9206C12.8105 16.7096 12.692 16.4235 12.692 16.1251C12.692 15.8268 12.8105 15.5406 13.0215 15.3296C13.2325 15.1187 13.5186 15.0001 13.817 15.0001C14.1154 15.0001 14.4015 15.1187 14.6125 15.3296C14.8235 15.5406 14.942 15.8268 14.942 16.1251C14.942 16.4235 14.8235 16.7096 14.6125 16.9206C14.4015 17.1316 14.1154 17.2501 13.817 17.2501Z" fill="#212F63" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_6121">
                      <rect width="18" height="18" fill="white" transform="translate(0.691406)" />
                    </clipPath>
                  </defs>
                </svg>

              </div>
            </Link>
            <div onClick={() => { toggleModalForRegisterForSearch() }} className="product-icon search_bar_icon">
              {/* <p>Search</p> */}
              <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_6095)">
                  <path d="M13.6717 12.4628L16.8839 15.6743L15.8227 16.7355L12.6112 13.5233C11.4162 14.4812 9.92994 15.0022 8.39844 15C4.67244 15 1.64844 11.976 1.64844 8.25C1.64844 4.524 4.67244 1.5 8.39844 1.5C12.1244 1.5 15.1484 4.524 15.1484 8.25C15.1506 9.7815 14.6296 11.2678 13.6717 12.4628ZM12.1672 11.9063C13.119 10.9274 13.6506 9.61532 13.6484 8.25C13.6484 5.349 11.2987 3 8.39844 3C5.49744 3 3.14844 5.349 3.14844 8.25C3.14844 11.1503 5.49744 13.5 8.39844 13.5C9.76376 13.5022 11.0759 12.9706 12.0547 12.0188L12.1672 11.9063V11.9063Z" fill="#212F63" />
                </g>
                <defs>
                  <clipPath id="clip0_1_6095">
                    <rect width="18" height="18" fill="white" transform="translate(0.148438)" />
                  </clipPath>
                </defs>
              </svg>

            </div>
            {/* {
              tokenExist ?
                <section style={{ display: "flex" }}>
                  <Link to="/accounts">
                    <div className="product-icon" >

                      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_6140)">
                          <path d="M15.6914 16.5H14.1914V15C14.1914 14.4033 13.9544 13.831 13.5324 13.409C13.1104 12.9871 12.5381 12.75 11.9414 12.75H7.44141C6.84467 12.75 6.27237 12.9871 5.85042 13.409C5.42846 13.831 5.19141 14.4033 5.19141 15V16.5H3.69141V15C3.69141 14.0054 4.08649 13.0516 4.78976 12.3483C5.49302 11.6451 6.44684 11.25 7.44141 11.25H11.9414C12.936 11.25 13.8898 11.6451 14.5931 12.3483C15.2963 13.0516 15.6914 14.0054 15.6914 15V16.5ZM9.69141 9.75C9.10046 9.75 8.5153 9.6336 7.96933 9.40746C7.42337 9.18131 6.92729 8.84984 6.50943 8.43198C6.09156 8.01412 5.76009 7.51804 5.53395 6.97208C5.3078 6.42611 5.19141 5.84095 5.19141 5.25C5.19141 4.65905 5.3078 4.07389 5.53395 3.52792C5.76009 2.98196 6.09156 2.48588 6.50943 2.06802C6.92729 1.65016 7.42337 1.31869 7.96933 1.09254C8.5153 0.866396 9.10046 0.75 9.69141 0.75C10.8849 0.75 12.0295 1.22411 12.8734 2.06802C13.7173 2.91193 14.1914 4.05653 14.1914 5.25C14.1914 6.44347 13.7173 7.58807 12.8734 8.43198C12.0295 9.27589 10.8849 9.75 9.69141 9.75ZM9.69141 8.25C10.4871 8.25 11.2501 7.93393 11.8127 7.37132C12.3753 6.80871 12.6914 6.04565 12.6914 5.25C12.6914 4.45435 12.3753 3.69129 11.8127 3.12868C11.2501 2.56607 10.4871 2.25 9.69141 2.25C8.89576 2.25 8.13269 2.56607 7.57009 3.12868C7.00748 3.69129 6.69141 4.45435 6.69141 5.25C6.69141 6.04565 7.00748 6.80871 7.57009 7.37132C8.13269 7.93393 8.89576 8.25 9.69141 8.25Z" fill="#212F63" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_6140">
                            <rect width="18" height="18" fill="white" transform="translate(0.691406)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </Link> */}

            {
              tokenExist ?
                <div ref={dropdownRef}>
                  <div

                    className="product-icon" onClick={toggleDropdown}>

                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1_6140)">
                        <path d="M15.6914 16.5H14.1914V15C14.1914 14.4033 13.9544 13.831 13.5324 13.409C13.1104 12.9871 12.5381 12.75 11.9414 12.75H7.44141C6.84467 12.75 6.27237 12.9871 5.85042 13.409C5.42846 13.831 5.19141 14.4033 5.19141 15V16.5H3.69141V15C3.69141 14.0054 4.08649 13.0516 4.78976 12.3483C5.49302 11.6451 6.44684 11.25 7.44141 11.25H11.9414C12.936 11.25 13.8898 11.6451 14.5931 12.3483C15.2963 13.0516 15.6914 14.0054 15.6914 15V16.5ZM9.69141 9.75C9.10046 9.75 8.5153 9.6336 7.96933 9.40746C7.42337 9.18131 6.92729 8.84984 6.50943 8.43198C6.09156 8.01412 5.76009 7.51804 5.53395 6.97208C5.3078 6.42611 5.19141 5.84095 5.19141 5.25C5.19141 4.65905 5.3078 4.07389 5.53395 3.52792C5.76009 2.98196 6.09156 2.48588 6.50943 2.06802C6.92729 1.65016 7.42337 1.31869 7.96933 1.09254C8.5153 0.866396 9.10046 0.75 9.69141 0.75C10.8849 0.75 12.0295 1.22411 12.8734 2.06802C13.7173 2.91193 14.1914 4.05653 14.1914 5.25C14.1914 6.44347 13.7173 7.58807 12.8734 8.43198C12.0295 9.27589 10.8849 9.75 9.69141 9.75ZM9.69141 8.25C10.4871 8.25 11.2501 7.93393 11.8127 7.37132C12.3753 6.80871 12.6914 6.04565 12.6914 5.25C12.6914 4.45435 12.3753 3.69129 11.8127 3.12868C11.2501 2.56607 10.4871 2.25 9.69141 2.25C8.89576 2.25 8.13269 2.56607 7.57009 3.12868C7.00748 3.69129 6.69141 4.45435 6.69141 5.25C6.69141 6.04565 7.00748 6.80871 7.57009 7.37132C8.13269 7.93393 8.89576 8.25 9.69141 8.25Z" fill="#212F63" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_6140">
                          <rect width="18" height="18" fill="white" transform="translate(0.691406)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <div
                    id="user-dropdown"
                    className="main_dropdown"
                    // ref={dropdownRef}
                    style={{
                      display: dropdownIsOpen ? 'flex' : 'none',
                      position: 'absolute',
                      right: '20px',
                      zIndex: 1000,
                      flexDirection: 'column',
                      height: '90px',
                      borderRadius: '2px',
                      backgroundColor: 'white',
                      padding: '10px',
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                      alignItems: 'start',
                      paddingLeft: '3px',

                    }}
                  >
                    <Link
                      to="/accounts"
                      ref={dropdownRef}
                      style={{
                        textDecoration: 'none',
                        color: '#212F63',
                        cursor: 'pointer',
                        marginBottom: '5px',

                        fontSize: '14px',
                        fontWeight: 400,
                        paddingLeft: '10px',
                      }}
                    >
                      My Account
                    </Link>
                    <Link
                      to="/Changepassword"
                      ref={dropdownRef}
                      style={{
                        textDecoration: 'none',
                        color: '#212F63', // Text color
                        cursor: 'pointer',
                        marginBottom: '5px',
                        fontSize: '14px', // Font size
                        fontWeight: 400, // Font weight
                        textAlign: 'left',
                        paddingLeft: '10px',
                      }}
                    >
                      Change Password
                    </Link>
                    <div
                      style={{
                        textDecoration: 'none',
                        color: '#212F63', // Text color
                        cursor: 'pointer',
                        marginBottom: '5px',
                        fontSize: '14px', // Font size
                        fontWeight: 400, // Font weight
                        textAlign: 'left',
                        paddingLeft: '10px',
                      }}
                      onClick={logoutFunction}
                    >
                      Logout
                    </div>
                  </div>



                </div>
                :
                <div className="product-icon" onClick={() => { toggleModalForRegister(true) }}>
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1_6140)">
                      <path d="M15.6914 16.5H14.1914V15C14.1914 14.4033 13.9544 13.831 13.5324 13.409C13.1104 12.9871 12.5381 12.75 11.9414 12.75H7.44141C6.84467 12.75 6.27237 12.9871 5.85042 13.409C5.42846 13.831 5.19141 14.4033 5.19141 15V16.5H3.69141V15C3.69141 14.0054 4.08649 13.0516 4.78976 12.3483C5.49302 11.6451 6.44684 11.25 7.44141 11.25H11.9414C12.936 11.25 13.8898 11.6451 14.5931 12.3483C15.2963 13.0516 15.6914 14.0054 15.6914 15V16.5ZM9.69141 9.75C9.10046 9.75 8.5153 9.6336 7.96933 9.40746C7.42337 9.18131 6.92729 8.84984 6.50943 8.43198C6.09156 8.01412 5.76009 7.51804 5.53395 6.97208C5.3078 6.42611 5.19141 5.84095 5.19141 5.25C5.19141 4.65905 5.3078 4.07389 5.53395 3.52792C5.76009 2.98196 6.09156 2.48588 6.50943 2.06802C6.92729 1.65016 7.42337 1.31869 7.96933 1.09254C8.5153 0.866396 9.10046 0.75 9.69141 0.75C10.8849 0.75 12.0295 1.22411 12.8734 2.06802C13.7173 2.91193 14.1914 4.05653 14.1914 5.25C14.1914 6.44347 13.7173 7.58807 12.8734 8.43198C12.0295 9.27589 10.8849 9.75 9.69141 9.75ZM9.69141 8.25C10.4871 8.25 11.2501 7.93393 11.8127 7.37132C12.3753 6.80871 12.6914 6.04565 12.6914 5.25C12.6914 4.45435 12.3753 3.69129 11.8127 3.12868C11.2501 2.56607 10.4871 2.25 9.69141 2.25C8.89576 2.25 8.13269 2.56607 7.57009 3.12868C7.00748 3.69129 6.69141 4.45435 6.69141 5.25C6.69141 6.04565 7.00748 6.80871 7.57009 7.37132C8.13269 7.93393 8.89576 8.25 9.69141 8.25Z" fill="#212F63" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_6140">
                        <rect width="18" height="18" fill="white" transform="translate(0.691406)" />
                      </clipPath>
                    </defs>
                  </svg>

                </div>
            }
            {/* {tokenExist && ( // Conditionally render dropdown only if logged in */}

            {/* )} */}
            {/* <div onClick={logoutFunction} className="product-icon" >
                    Logout
                  </div> */}
            {/* </section> */}
            {/* :
                <div className="product-icon" onClick={() => { toggleModalForRegister(true) }}>
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1_6140)">
                      <path d="M15.6914 16.5H14.1914V15C14.1914 14.4033 13.9544 13.831 13.5324 13.409C13.1104 12.9871 12.5381 12.75 11.9414 12.75H7.44141C6.84467 12.75 6.27237 12.9871 5.85042 13.409C5.42846 13.831 5.19141 14.4033 5.19141 15V16.5H3.69141V15C3.69141 14.0054 4.08649 13.0516 4.78976 12.3483C5.49302 11.6451 6.44684 11.25 7.44141 11.25H11.9414C12.936 11.25 13.8898 11.6451 14.5931 12.3483C15.2963 13.0516 15.6914 14.0054 15.6914 15V16.5ZM9.69141 9.75C9.10046 9.75 8.5153 9.6336 7.96933 9.40746C7.42337 9.18131 6.92729 8.84984 6.50943 8.43198C6.09156 8.01412 5.76009 7.51804 5.53395 6.97208C5.3078 6.42611 5.19141 5.84095 5.19141 5.25C5.19141 4.65905 5.3078 4.07389 5.53395 3.52792C5.76009 2.98196 6.09156 2.48588 6.50943 2.06802C6.92729 1.65016 7.42337 1.31869 7.96933 1.09254C8.5153 0.866396 9.10046 0.75 9.69141 0.75C10.8849 0.75 12.0295 1.22411 12.8734 2.06802C13.7173 2.91193 14.1914 4.05653 14.1914 5.25C14.1914 6.44347 13.7173 7.58807 12.8734 8.43198C12.0295 9.27589 10.8849 9.75 9.69141 9.75ZM9.69141 8.25C10.4871 8.25 11.2501 7.93393 11.8127 7.37132C12.3753 6.80871 12.6914 6.04565 12.6914 5.25C12.6914 4.45435 12.3753 3.69129 11.8127 3.12868C11.2501 2.56607 10.4871 2.25 9.69141 2.25C8.89576 2.25 8.13269 2.56607 7.57009 3.12868C7.00748 3.69129 6.69141 4.45435 6.69141 5.25C6.69141 6.04565 7.00748 6.80871 7.57009 7.37132C8.13269 7.93393 8.89576 8.25 9.69141 8.25Z" fill="#212F63" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_6140">
                        <rect width="18" height="18" fill="white" transform="translate(0.691406)" />
                      </clipPath>
                    </defs>
                  </svg>

                </div> */}
            {/* } */}
          </div>


        </div>

      </div>

      {showWelcomeMessage && (
        <div className="welcome-message" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', margin: '10px 0', backgroundColor: '#f5f5f5' }}>
          <div style={{ textAlign: 'center', marginRight: '10px' }}>
            <p>Welcome to <span style={{ fontWeight: 'bold', color: '#ffcc00' }}>Anycarpart</span>! We're thrilled to have you join our community of car enthusiasts.</p>
          </div>
          <button type="button" onClick={handleCloseWelcomeMessage} style={{ border: 'none', background: 'transparent', padding: '0', cursor: 'pointer' }}>
            <span aria-label="Close" role="img">❌</span>
          </button>
        </div>
      )}
      {/* working */}
      <header style={{ position: "relative" }}>
        <nav className="navbar-menu-sec">
          <div className="logo-design">
            <a href="#"><img src="/assets/logo.png" alt="Logo " /></a>
          </div>

          {/* {windowWidth > 768 && (
        <div className="logo-toggle-bar">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="23" height="23" stroke="#F86B1F" />
            <g clipPath="url(#clip0_1_6148)">
              <path d="M5.25 6H18.75V7.5H5.25V6ZM5.25 11.25H18.75V12.75H5.25V11.25ZM5.25 16.5H18.75V18H5.25V16.5Z" fill="#F86B1F" />
            </g>
            <defs>
              <clipPath id="clip0_1_6148">
                <rect width="18" height="18" fill="white" transform="translate(3 3)" />
              </clipPath>
            </defs>
          </svg>
        </div>
        
      )} */}

          {/* <div className="hamburger">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div> */}
          {/* <ul className="nav-links">
            {
              menuLists.map((menuListsData) => {
                switch (menuListsData.type) {
                  case "anchor":
                    return (
                      menuListsData.target == `_blanck` ?
                      <Link target={menuListsData.target} to={menuListsData.url}><p>{menuListsData.name}</p></Link>
                      :
                      <Link to={menuListsData.url}><p>{menuListsData.name}</p></Link>
                    )
                  case "category":
                    return <p onClick={() => { menuSubCategoriesHandler(menuListsData.name) }} >{menuListsData.name}</p>
                  //  break;
                  case "subcategory":
                    return <p onClick={() => { menuSubCategoriesHandler(menuListsData.name) }} >{menuListsData.name}</p>
                  case "service":
                    return <p onClick={() => { menuSubCategoriesHandler(menuListsData.name) }} >{menuListsData.name}</p>

                  // something to append that image in status data mapping
                  //  break;
                }
              }
              )
            }
            </ul> */}
          {/* <li onClick={handleMouseEnter} ><a href="#">hjbajhsdvajhsvdj</a></li>
            <li onClick={handleMouseEnter1}>bnsavdbansvdaqwdqwdqw</li>
            <li onClick={handleMouseEnter}>{t('HeaderCar')}qwdqwd</li>
            <li onClick={handleMouseEnter}>{t('HeaderInterior')}</li>
            <li onClick={handleMouseEnter}>{t('HeaderExterior')}</li>
            <li onClick={handleMouseEnter}>{t('HeaderPerformance')}</li>
            <li onClick={handleMouseEnter}>{t('HeaderLightning')}</li>
            <li onClick={handleMouseEnter}>{t('HeaderTyres')}</li>
            <li onClick={handleMouseEnter}>{t('HeaderBody')}</li>
            <li onClick={handleMouseEnter}>{t('HeaderAudio')}</li>
            <li onClick={handleMouseEnter}>{t('HeaderAutomotive')}</li>
            <li onClick={handleMouseEnter}>{t('HeaderSpeciality')}</li> */}

          <div className='menu-list-items'>
            {windowWidth <= 768 && (
              <div className="mobile-menu-button" onClick={toggleMenu}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="23" height="23" stroke="#F86B1F" />
                  <g clipPath="url(#clip0_1_6148)">
                    <path d="M5.25 6H18.75V7.5H5.25V6ZM5.25 11.25H18.75V12.75H5.25V11.25ZM5.25 16.5H18.75V18H5.25V16.5Z" fill="#F86B1F" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_6148">
                      <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )}
            {(windowWidth > 768 || isMenuOpen) && (
              <ul className="nav-links">
                {menuLists.map((menuListsData) => {
                  const isActive = activeMenu === menuListsData.name;
                  switch (menuListsData.type) {
                    case 'anchor':
                      return menuListsData.target === '_blank' ? (
                        <a href={menuListsData.url} target="_blank" rel="noopener noreferrer" key={menuListsData.name} className={isActive ? 'active' : ''}>
                          <p>{menuListsData.name}</p>
                        </a>
                      ) : (
                        <Link to={menuListsData.url}>
                          <p>{menuListsData.name}</p>
                        </Link>
                      );
                    case 'category':
                    case 'subcategory':
                    case 'service':
                      return (
                        <p
                          key={menuListsData.name}
                          className={isActive ? 'active' : ''}
                          onClick={() => menuSubCategoriesHandler(menuListsData.name)}>
                          {menuListsData.name}
                        </p>
                      );
                    default:
                      return null;
                  }
                })}
              </ul>
            )}
          </div>
        </nav>
      </header>

      {
        isOpen && <div onClick={handleTabClick} className={style.dropdown_main_wrapper} >

          {/* isOpen && <div  className={style.dropdown_main_wrapper} > */}

          <section>
            {
              subMenuData.map((subMenu, i) => {
                console.log(subMenu, "hhhhhhsss")
                return subMenu.thumbnail ? <SubCategoriesCard key={i} title={subMenu?.title} imageURL={subMenu.thumbnail} subMenu={subMenu} /> : <SubCategoriesCard key={i} title={subMenu?.title} imageURL={subMenu.image} subMenu={subMenu} />
              })
            }
          </section>
          <ToastContainer />
        </div>
      }
    </>
  )
}
