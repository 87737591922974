import React from 'react';
import style from './myaccount.module.css';
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import HeroImage from '../../components/hero-image/HeroImage';
import Image from '../../assets/images/accountPage.png'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL, IMG_URL } from '../../config/config';
import Checkbox from '@mui/material/Checkbox';
import CancelOrder from '../../components/order/DynamicFieldComponents';
import ReturnOrder from '../../components/order/returnOrder';
import RefundOrder from '../../components/order/refundOrder';
function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Myaccount() {

  const [value, setValue] = React.useState(0);
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const API_BASE_URL = `https://admin-anycarpart.go-demo.com/api/v1`;
  const imageURL = `https://admin-anycarpart.go-demo.com`;
  const fetchConfig = getFetchConfig();
  const { t } = useTranslation();

  const [productOrder, setProductOrder] = useState([]);
  const [address, setAddress] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const location = useLocation()
  const message = new URLSearchParams(location.search).get('message');
  const [selectedItems, setSelectedItems] = useState([]);
  const [isCancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);

  const openCancelOrderModal = () => {
    setCancelOrderModalOpen(true);
  };

  const closeCancelOrderModal = () => {
    setCancelOrderModalOpen(false);
  };
  const handleCheckboxChange = (orderIndex) => {
    if (selectedItems.includes(orderIndex)) {
      setSelectedItems(selectedItems.filter(item => item !== orderIndex));
    } else {
      setSelectedItems([...selectedItems, orderIndex]);
    }
  };
  const getProductOrder = (productById) => {
    fetch(`${API_BASE_URL}/productOrder`, {
      ...fetchConfig,
      method: 'POST',
      body: JSON.stringify({
        productId: productById,
      }),
    })
      .then(handleFetchErrors)
      .then((dataplace) => {
        console.log(dataplace.data.orders, "getproductpi");
        setProductOrder(dataplace.data.orders);
        // console.log(dataplace.data.orders[0].shippingAddress.state, "shiiping");
        setAddress(dataplace.data.orders.shippingAddress)
        setOrderDetails(dataplace.data.orders.orderDetail);
        // window.location.reload()
      })
      .then(handleFetchErrors)
      .then((dataplace) => {
        console.log(dataplace.data, "getproductpi");
      })
      .catch(standardFetchHandlers.error);
  }

  useEffect(() => {
    getProductOrder();
  }, []);

  useEffect(() => {
    if (message === 'OrderPlaced') {
      toast.success('Your order is placed successfully!');
      navigate('/'); 
      return
    } else if (message === "failed") {
      toast.error('Your transaction has failed! but no worries you can retry the payment')
      return
    }
  }, [message]);


  const navigatorHandler = (id) => {
    navigate(`/paymentForm/${id}`)
  }


  // Function to calculate the difference in days between two dates
  const getDaysDifference = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };






  return (

    <div className={style.main_wrapper1}>
      <HeroImage
        Heading={t('AccountsName')}
        HeroImage={Image}
      />
      <div className={style.main_wrapper}>
        <div className={style.order_lists}>
          <div className={style.account_order_lists}>
            <ul>
              <Link to="/accounts">
                <li className={style.clickable_text}>
                  <a href="#">My details</a>
                </li>
              </Link>
              <Link to="/orders">
                <li className={style.clickable_text}>
                  <a href="#">My Orders</a>
                </li>
              </Link>
              <li>
                <Link to="/my-special-orders">
                  <li className={style.clickable_text}>
                    <a href="#">Special Orders</a>
                  </li>
                </Link>
              </li>


              <Link to="/Changepassword">
                <li className={style.clickable_text}>
                  <a href="#">Change password</a>
                </li>
              </Link>

              <li className={style.clickable_text}>
                <a href="#">Sign out</a>
              </li>
            </ul>
          </div>
          <div className={style.account_order_accordion_lists}>
            <h1>My Orders</h1>
            <div className={style.account_order_accordion_list_bar}>
              {productOrder && productOrder.length > 0 ? (
                <>
                  {productOrder.map((product, index) => (
                    <div key={index}>
                      <Accordion defaultExpanded={index === 0}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div className={style.order_lists_bar}>
                            <div className={style.accordion_lists_bar}>
                              <div>
                                <p>Order No</p>
                                <span>#{String(1001 + index)}</span>

                              </div>
                              <div>
                                {product?.shippingAddress?.address == null ? (<p>Pick To Strore</p>) : (<p>Address</p>)}
                                <span>
                                  {product?.shippingAddress?.address
                                    ? `${product?.shippingAddress?.address}`
                                    // ? `${product.shippingAddress.address}, ${product.shippingAddress.city}, ${product.shippingAddress.state}, ${product.shippingAddress.city}`
                                    : 'Not To Yet'}
                                </span>
                              </div>
                            </div>
                            <div className={style.accordion_lists_bar1}>
                              <div>
                                <p>Date</p>
                                <span>{new Date(product.createdAt).toLocaleDateString()}</span>

                              </div>
                              <div>
                                <p>Status</p>
                                {product.status === 'payment failed' ? (
                                  <div style={{ color: 'red' }}>
                                    Payment <br /> Failed
                                  </div>
                                ) : (
                                  <span style={{ color: '' }} className={style.orange_text}>
                                    {product.status}
                                  </span>
                                )}
                              </div>




                              <div>
                                {product.status === "payment failed" &&
                                  <button onClick={() => { navigatorHandler(product._id) }} className={style.cancel_button} style={{ paddingInline: 30, border: 'none', backgroundColor: 'transparent', whiteSpace: 'nowrap', marginRight: '10px', marginBottom: '30px' }}>
                                    Retry Payment
                                  </button>
                                }




                                <Link to={`/order/cancel/${product._id}`}>
                                  {product.status === "inprogress" && (
                                    <span className={style.cancel_button} onClick={() => openCancelOrderModal()}>
                                      Cancel Order
                                    </span>
                                  )}
                                </Link>

                                {(product.status === "delivered" || product.status === "completed") && product.productDetails.returnable === 'Yes' && getDaysDifference(new Date(product.createdAt), new Date()) <= product.productDetails.returnableDays ? (
                                  <Link to={`/order/return/${product._id}`}>
                                    <span className={style.cancel_button} onClick={() => openCancelOrderModal()}>
                                      Return Order
                                    </span>
                                  </Link>
                                ) : null}



                              </div>

                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>

                          <div className={style.order_lists_bar}>


                            <div className={style.accordion_lists_addres}>
                              {console.log('product,,,,', product.paymentType)}
                              <div>
                                <p>Order Id </p>
                                <p>Date and time</p>
                                {product.shippingAddress.address == null ? (<p>Pick To Strore</p>) : (<p>Address</p>)}
                                {product.paymentType === 'cod' ? (
                                  <p>Amount Due</p>
                                ) : (
                                  <p>Amount Paid</p>
                                )}
                                <p>Payment method</p>
                                <p>Country</p>
                                <p>State</p>
                                <p>city</p>
                                {product.shippingAddress.zipCode == null ? (<p>Store Name</p>) : (<p>Postal Code</p>)}
                              </div>
                              <div >
                                <h5>{product._id}</h5>
                                <h5>{new Date(product.createdAt).toLocaleDateString() + ' , ' + new Date(product.createdAt).toLocaleTimeString()}</h5>
                                <h5 title={product.shippingAddress.address}>
                                  {product.shippingAddress.address == null ? "Not To Yet" : product.shippingAddress.address}
                                </h5>
                                <h5 style={{ color: "#F86B1F" }}>{product.status == "paid" ? "Paid" : product?.total?.toFixed(2)}</h5>
                                <h5>{product.paymentType}</h5>
                                <h5>{product.shippingAddress.country || "Saudi Arabia"}</h5>
                                <h5>{product.shippingAddress.state || product.pickuptostore.state}</h5>
                                <h5>{product.shippingAddress.city || product.pickuptostore.city}</h5>
                                <h5>{product.shippingAddress.zipCode || product.pickuptostore.store}</h5>
                              </div>
                            </div>
                            <div className={style.accordion_lists_order}>
                              <div className={style.checkout_order_summery}>
                                <h2>
                                  {/* <input
                                    style={{ marginRight: "10px" }}
                                    type="checkbox"
                                  // onChange={() => handleCheckboxChange(orderIndex)}
                                  // checked={selectedItems.includes(orderIndex)}
                                  /> */}
                                  Order composition:</h2>
                                {product.orderDetail.map((order, orderIndex) => (

                                  <div className={style.checkout_items_products}>


                                    <div key={orderIndex} className={style.checkout_items_oil_filters}>
                                      <div >
                                        {/* <input
                                          style={{ marginRight: "10px" }}
                                          type="checkbox"
                                          onChange={() => handleCheckboxChange(orderIndex)}
                                          checked={selectedItems.includes(orderIndex)}
                                        /> */}
                                      </div>
                                      <div>
                                        {/* <img src={order.productImage} alt="" /> */}
                                        <img src={`${IMG_URL}/${order.productImage[0]}`} alt="" />
                                        {/* {order.orderDetail && order.orderDetail.length > 0 && (
                                          <img src={`${IMG_URL}/${order.productImage[0]}`} alt="" />
                                        )} */}

                                      </div>

                                      <div>
                                        <h5>{order.productName}</h5>
                                      </div>
                                      <div>
                                        {/* <h4>{order.productDescription}</h4> */}
                                      </div>

                                    </div>

                                    <div>
                                      <div>
                                        <h5>{order.price}SAR</h5>
                                      </div>
                                      <div>
                                        <h4>{order.quantity} item</h4>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className={style.productdetailslistMainDiv}>
                                  <div className={style.productdetailslist} >
                                    <div>
                                      <h5>Vat</h5>
                                    </div>

                                    <div>
                                      <p>{product?.vat?.toFixed(2)} SAR</p>
                                    </div>

                                  </div>
                                  <div className={style.productdetailslist}>
                                    <div>
                                      <h5>ShippingCost</h5>
                                    </div>
                                    <div>
                                      <p>{product.shippingCost} SAR</p>
                                    </div>

                                  </div>

                                  <div className={style.productdetailslist}>
                                    <div>
                                      <h5>Total Amount</h5>
                                    </div>
                                    <div>
                                      <p style={{ color: "#F86B1F" }}>{product?.total?.toFixed(2)} SAR</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                    </div>
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>

      </div>

    </div>

  )
}


